import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import * as amplitude from "@amplitude/analytics-browser";
import { observer } from "mobx-react";
import Footer from "../Components/Footer";
import CertificatePDF from "../Components/CertificatePDF";
import Loading from "../Components/Loading";
import EmailProfile from "../Components/EmailProfile";

const RQ05RequestReject = observer(() => {
  const location = useLocation();
  const {
    id,
    name,
    birthday,
    address,
    workDate,
    companyName,
    telNumber,
    position,
    department,
    jobPosition,
    jobList,
    requestDate,
    requestNumber,
    email,
    uid,
    ctfId,
    loseDate,
  } = location.state || {};

  const navigate = useNavigate();
  const [rejectReason, setRejectReason] = useState("");
  const [isReasonErrorModal, setIsReasonErrorModal] = useState(false);
  const [isReasonCompleteModal, setIsReasonCompleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRejectReasonChange = (event) => {
    setRejectReason(event.target.value);
  };

  const onClickCompleteConfirm = async () => {
    const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/reject";
    amplitude.track("증명서 발급 거절 실행", {
      증명서종류: workDate.includes("현재") ? "재직증명서" : "경력증명서",
      직장명: companyName,
    });
    try {
      setIsLoading(true);
      const response = await axios.get(endPoint, {
        params: {
          rejectReason: rejectReason,
          email: email,
          userId: uid,
          ctfId: ctfId,
          companyName: companyName,
        },
      });

      if (response.data.status === "증명서 발급 거절") {
        setIsLoading(false);
        amplitude.track("증명서 발급 거절 완료 페이지 진입", {
          증명서종류: workDate.includes("현재") ? "재직증명서" : "경력증명서",
          직장명: companyName,
        });
        navigate(`/email/${id}/reject/complete`, {
          state: {
            reason: rejectReason,
            id: id,
            name: name,
            birthday: birthday,
            address: address,
            workDate: workDate,
            companyName: companyName,
            telNumber: telNumber,
            position: position,
            department: department,
            jobPosition: jobPosition,
            jobList: jobList,
            requestDate: requestDate,
            requestNumber: requestNumber,
            email: email,
            uid: uid,
            ctfId: ctfId,
            loseDate: loseDate,
          },
        });
      } else {
        alert("경력증명서 발급 거절을 실패하였습니다. 다시 시도해주세요.");
      }
    } catch (error) {
      console.error("Error during axios.get:", error);
    }
  };

  const onClickCompleteCancle = async () => {
    setIsReasonCompleteModal(false);
  };

  const onClickErrorConfirm = async () => {
    setIsReasonErrorModal(false);
  };

  const onClickRejectNone = async () => {
    setIsReasonErrorModal(true);
  };

  const onClickRejectDone = async () => {
    amplitude.track("거절 사유 확인 모달 진입");
    setIsReasonCompleteModal(true);
  };

  const screenWidth = window.innerWidth;

  return (
    <>
      {isLoading ? <Loading /> : null}
      {isReasonErrorModal && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Issue_Error_Text_CT">
                <div className="Email_Sub_Error_Copy">발급 거절 사유를 입력해주세요.</div>
              </div>
              <button className="Issue_Error_Button" onClick={onClickErrorConfirm}>
                <div className="Email_Right_Button_BG" style={{ marginLeft: "0px" }}>
                  확인
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
      {isReasonCompleteModal && (
        <div className="Signin_CompleteModal_BG">
          <div className="Issue_Seal_Modal_CT">
            <div className="Issue_Error_BG">
              <div className="Email_InfoBox_BG_CT" style={{ marginTop: "0px" }}>
                <div
                  className="Email_InfoBox_BG"
                  style={{
                    backgroundColor: "rgb(242, 244, 246)",
                  }}
                >
                  <div className="Email_InfoBox_1_Copy_CT">
                    <div className="Email_InfoBox_1_Copy" style={{ color: "rgb(70, 70, 70)" }}>
                      거절 사유
                    </div>
                  </div>
                  <div className="Email_InfoBox_2_Copy">{rejectReason}</div>
                </div>
              </div>
              <div className="Issue_Error_Complete_Text_CT">
                <div className="Email_Sub_Error_Copy">상기의 사유로 경력증명서 발급이 거절됩니다.</div>
              </div>

              <div className="Email_RightArea_Buttons_CT">
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickCompleteCancle}>
                  <div
                    className="Email_Left_Button_BG"
                    style={{
                      borderColor: "rgb(231, 239, 255)",
                      backgroundColor: "rgb(231, 239, 255)",
                      color: "rgb(55, 105, 252)",
                    }}
                  >
                    수정
                  </div>
                </button>
                <button className="Email_Button" style={{ marginTop: "0px" }} onClick={onClickCompleteConfirm}>
                  <div className="Email_Right_Button_BG">확인</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {screenWidth > 1023 ? (
        <div className="AllContent_Between">
          <div className="Email_CT">
            <div className="Email_LeftArea_CT">
              <div className="Email_LeftContents_CT_Reject">
                <div className="Email_Main_Copy_CT">
                  <div className="Email_Main_1_Copy_CT">
                    <div className="Email_Main_2_Copy">{name}님</div>
                    <div className="Email_Main_1_Copy">의</div>
                  </div>
                  <div className="Email_Main_1_Copy">경력증명서 발급 거절사유를 입력해주세요.</div>
                </div>
                <div className="Email_Sub_Copy_CT">
                  <div className="Email_Sub_1_Copy">
                    경력증명서 발급 거절 사유를 입력해주세요. 입력한 거절 사유는 <span style={{ fontWeight: "800", color: "rgb(55, 105, 252)" }}>{name}님</span>
                    께 전달됩니다.
                  </div>
                </div>
                <div className="Reject_Reason_CT">
                  <div className="Reject_Reason_BG">
                    <textarea
                      className="Reject_Reason_TextArea"
                      name="message"
                      id="userMessage"
                      placeholder="발급 거절 사유를 입력해주세요."
                      value={rejectReason}
                      onChange={handleRejectReasonChange}
                      required
                    />
                    <div className="Reject_Reason_Divider"></div>
                    <button className="Email_Button" style={{ marginTop: "0px" }} onClick={rejectReason.length > 0 ? onClickRejectDone : onClickRejectNone}>
                      <div className="Reject_Reason_Button_BG">거절 진행</div>
                    </button>
                  </div>
                  <div className="Email_LawInfo_Copy_CT">
                    <div className="Email_LawInfo_Copy">
                      재직자가 퇴사하고 3년 이내에 경력증명서 발급을 요구하는 경우 근로기준법 제39조에 따라{" "}
                      <span style={{ fontWeight: "600", color: "rgb(70, 70, 70)" }}>7일 이내 발급 의무</span>가 있습니다. 정당한 사유 없이 거부 하는 경우,
                      근로기준법 제116조에 따라 500만원 이하의 과태료 가 부과될 수 있습니다.
                    </div>
                  </div>
                </div>
              </div>
              <EmailProfile />
            </div>
            <div className="Email_RightArea_CT">
              <CertificatePDF
                name={name}
                birthday={birthday}
                address={address}
                workDate={workDate}
                companyName={companyName}
                telNumber={telNumber}
                position={position}
                department={department}
                jobPosition={jobPosition}
                jobList={jobList}
                requestDate={requestDate}
                requestNumber={requestNumber}
                loseDate={loseDate}
              />

              {/* <div className="Email_RightArea_Body_CT">
                <div className="Email_RightArea_Body_Copy">
                  해당 경력증명서 발급 요청은 <span style={{ fontWeight: "800" }}>{name} 님</span>으로부터
                  <span style={{ fontWeight: "800" }}>써티드</span>와 <span style={{ fontWeight: "800" }}>인사노무컨설팅</span>이 위임받았습니다. 경력증명서의 내용이
                  사실이라면 <span style={{ fontWeight: "800", color: "rgb(55, 105, 252)" }}>'발급 진행'</span> 버튼을 클릭해 <span style={{ fontWeight: "800" }}>직인</span>과{" "}
                  <span style={{ fontWeight: "800" }}>대표자명</span>을 추가해주세요.
                </div>
              </div>

              <div className="Email_RightArea_Buttons_CT">
                <button
                  className="Email_Button"
                  style={{ marginTop: "0px" }}
                  onClick={() =>
                    navigate(`/email/${id}/issue`, {
                      state: {
                        id: id,
                        name: name,
                        birthday: birthday,
                        address: address,
                        telNumber: telNumber,
                        workDate: workDate,
                        companyName: companyName,
                        position: position,
                        department: department,
                        jobPosition: jobPosition,
                        jobList: jobList,
                        requestDate: requestDate,
                        requestNumber: requestNumber,
                        email: email,
                        uid: uid,
                        ctfId: ctfId,
                      },
                    })
                  }
                >
                  <div className="Email_Right_Button_BG">발급 진행</div>
                </button>
              </div> */}
            </div>
          </div>
          {screenWidth > 1023 ? (
            <div>
              <Footer />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="AllContent_Between_Detail">
          <div className="Email_CT">
            <div className="Email_LeftArea_CT">
              <div className="Email_LeftContents_CT_Reject">
                <div className="Email_Main_Copy_CT">
                  <div className="Email_Main_1_Copy" style={{ width: "320px" }}>
                    <span
                      style={{
                        color: "rgb(55,105,252)",
                        fontWeight: "600",
                      }}
                    >
                      {name}님
                    </span>
                    의 경력증명서 발급 거절사유를 입력해주세요.
                  </div>
                </div>
                <div className="Email_Sub_Copy_CT">
                  <div className="Email_Sub_1_Copy">
                    경력증명서 발급 거절 사유를 입력해주세요.
                    <br />
                    입력한 거절 사유는 <span style={{ fontWeight: "800", color: "rgb(55, 105, 252)" }}>{name}님</span>께 전달됩니다.
                  </div>
                </div>
                <div className="Reject_Reason_CT">
                  <div className="Reject_Reason_CenterCT">
                    <div className="Reject_Reason_BG">
                      <textarea
                        className="Reject_Reason_TextArea"
                        name="message"
                        id="userMessage"
                        placeholder="발급 거절 사유를 입력해주세요."
                        value={rejectReason}
                        onChange={handleRejectReasonChange}
                        required
                      />
                      <div className="Reject_Reason_Divider"></div>
                      <button className="Email_Button" style={{ margin: "0px" }} onClick={rejectReason.length > 0 ? onClickRejectDone : onClickRejectNone}>
                        <div className="Reject_Reason_Button_BG">거절 진행</div>
                      </button>
                    </div>
                  </div>

                  <div className="Email_LawInfo_Copy_CT">
                    <div className="Email_LawInfo_Copy">
                      재직자가 퇴사하고 3년 이내에 경력증명서 발급을 요구하는 경우 근로기준법 제39조에 따라{" "}
                      <span style={{ fontWeight: "600", color: "rgb(70, 70, 70)" }}>7일 이내 발급 의무</span>가 있습니다. 정당한 사유 없이 거부 하는 경우,
                      근로기준법 제116조에 따라 500만원 이하의 과태료 가 부과될 수 있습니다.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Email_RightArea_CT">
              <CertificatePDF
                name={name}
                birthday={birthday}
                address={address}
                workDate={workDate}
                companyName={companyName}
                telNumber={telNumber}
                position={position}
                department={department}
                jobPosition={jobPosition}
                jobList={jobList}
                requestDate={requestDate}
                requestNumber={requestNumber}
                loseDate={loseDate}
              />

              <div className="Email_RightArea_Body_CT">
                <div className="Email_RightArea_Body_Copy">
                  해당 경력증명서 발급 요청은 <span style={{ fontWeight: "800" }}>{name} 님</span>으로부터
                  <span style={{ fontWeight: "800" }}>써티드</span>와 <span style={{ fontWeight: "800" }}>호연 인사노무컨설팅</span>이 위임받았습니다.
                  경력증명서의 내용이 사실이라면 <span style={{ fontWeight: "800", color: "rgb(55, 105, 252)" }}>'발급 진행'</span> 버튼을 클릭해{" "}
                  <span style={{ fontWeight: "800" }}>직인</span>과 <span style={{ fontWeight: "800" }}>대표자명</span>을 추가해주세요.
                </div>
              </div>

              <div className="Email_RightArea_Buttons_CT">
                <button
                  className="Email_Button"
                  style={{ marginTop: "0px" }}
                  onClick={() => {
                    amplitude.track("증명서 발급 거절 페이지에서 증명서 발급 진행 페이지 진입", {
                      증명서종류: workDate.includes("현재") ? "재직증명서" : "경력증명서",
                      직장명: companyName,
                    });
                    navigate(`/email/${id}/issue`, {
                      state: {
                        id: id,
                        name: name,
                        birthday: birthday,
                        address: address,
                        telNumber: telNumber,
                        workDate: workDate,
                        companyName: companyName,
                        position: position,
                        department: department,
                        jobPosition: jobPosition,
                        jobList: jobList,
                        requestDate: requestDate,
                        requestNumber: requestNumber,
                        email: email,
                        uid: uid,
                        ctfId: ctfId,
                      },
                    });
                  }}
                >
                  <div className="Email_Right_Button_BG">발급 진행</div>
                </button>
              </div>
              <EmailProfile />
            </div>
          </div>
          {screenWidth > 1023 ? (
            <div>
              <Footer />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
});

export default RQ05RequestReject;
