import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as amplitude from "@amplitude/analytics-browser";
import { observer } from "mobx-react";
import Footer from "../Components/Footer";
import CertificatePDF from "../Components/CertificatePDF";
import AlreadyConfirm from "../Assets/Icons/AlreadyConfirm";
import Loading from "../Components/Loading";
import EmailProfile from "../Components/EmailProfile";

const RQ02RequestDescription = observer(() => {
  const [response, setResponse] = useState(null);
  const [memberData, setMemberData] = useState(null);
  const [hiqcData, setHiqcData] = useState(null);
  const [requestDataStatus, setRequestDataStatus] = useState(null);
  const [isBlock, setIsBlock] = useState(null);
  const [isExpired, setExpired] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = new Date();
  const navigate = useNavigate();
  const screenWidth = window.innerWidth;
  const location = useLocation();
  const { token } = location.state || {};
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}년 ${month}월 ${day}일`;

  useEffect(() => {
    const laodRequestData = async () => {
      const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/web/single-page";
      setIsLoading(true);
      const responseRequest = await axios.get(endPoint, {
        params: { token: token },
      });
      if (responseRequest.data.status === "증명서 인증 유효기간 만료") {
        setIsLoading(false);
        setExpired(true);
      } else {
        setResponse(responseRequest.data.data.data);
        setHiqcData(responseRequest.data.data.HIQCData);
        setMemberData(responseRequest.data.data.MemberData);
        if (responseRequest) {
          const userEmail = responseRequest.data.data.MemberData.Email;
          const userId = responseRequest.data.data.MemberData.Uid;
          const endPoint = "https://pm94w2ttfc.execute-api.ap-northeast-2.amazonaws.com/v1/certificate/hiqc";
          try {
            const responseHIQC = await axios.get(endPoint, {
              params: { email: userEmail, userId: userId },
            });

            if (responseHIQC) {
              const HIQCList = responseHIQC.data.EmploymentData;
              const targetID = responseRequest.data.data.HIQCData.ID;
              const targetObject = HIQCList.find((item) => item.ID === targetID);
              if (targetObject) {
                const authStatus = targetObject.AuthStatus;
                setRequestDataStatus(authStatus);
                if (
                  authStatus === "발급 승인" ||
                  authStatus === "발급 거절" ||
                  authStatus === "발급 완료" ||
                  authStatus === "직접 대기" ||
                  authStatus === "직접 지연" ||
                  authStatus === "직접 완료"
                ) {
                  setIsBlock(true);
                }
                setIsLoading(false);
              }
            }
          } catch (error) {
            console.log("certificate/hiqc error : ", error);
          }
        }
      }
    };

    if (requestDataStatus === null) {
      if (token) {
        laodRequestData();
      }
    }
  }, [token, requestDataStatus]);

  const createStateObject = (token, response, formattedDate, memberData, hiqcData) => ({
    id: token,
    name: response ? response.name : "empty name",
    birthday: response ? response.birthday : "empty birthday",
    address: response ? response.address : "empty address",
    telNumber: response ? response.companyNumber : "empty telNumber",
    workDate: response ? response.employmentPeriod : "empty workDate",
    companyName: response ? response.companyName : "empty companyName",
    position: response ? response.position : "empty position",
    department: response ? response.department : "empty department",
    jobPosition: response ? response.jobPosition : "empty jobPosition",
    jobList: response ? response?.job : "empty jobList",
    requestDate: formattedDate,
    requestNumber: response ? response.ctfId : "empty requestNumber",
    email: memberData ? memberData.Email : "empty email",
    uid: memberData ? memberData.Uid : "empty uid",
    ctfId: hiqcData ? hiqcData.ID : "empty ctfId",
    loseDate: hiqcData ? hiqcData.LoseDate : "empty loseDate",
  });

  return (
    <>
      {isLoading ? <Loading /> : null}

      {screenWidth > 1023 ? (
        <div className="AllContent_Between">
          {!isBlock && !isExpired ? (
            <div className="Email_CT">
              <div className="Email_LeftArea_CT">
                <div className="Email_LeftContents_CT">
                  <div className="Email_Main_Copy_CT">
                    <div className="Email_Main_1_Copy_CT">
                      <div className="Email_Main_2_Copy">{memberData ? memberData.Name : ""}님</div>
                      <div className="Email_Main_1_Copy">의</div>
                    </div>
                    <div className="Email_Main_1_Copy">경력증명서 발급 요청을 확인해주세요.</div>
                  </div>
                  <div className="Email_Sub_Copy_CT">
                    <div className="Email_Sub_1_Copy">경력증명서의 내용을 확인하시고 발급 절차를 진행해주세요.</div>
                  </div>
                  <div className="Email_InfoBox_BG_CT">
                    <div className="Email_InfoBox_BG">
                      <div className="Email_InfoBox_1_Copy_CT">
                        <div className="Email_InfoBox_1_Copy">경력증명서 발급을 간편하게 하려면?</div>
                      </div>
                      <div className="Email_InfoBox_2_Copy">
                        경력증명서 내용의 사실 여부만 확인하시면 새롭게 경력증명서를 작성할 필요 없이 바로 경력증명서를 발급할 수 있습니다.
                      </div>
                    </div>
                  </div>
                  <div className="Email_LawInfo_Copy_CT">
                    <div className="Email_LawInfo_Copy">
                      재직자가 퇴사하고 3년 이내에 경력증명서 발급을 요구하는 경우 근로기준법 제39조에 따라 7일 이내 발급 의무가 있습니다. 정당한 사유 없이
                      거부하는 경우, 근로기준법 제116조에 따라 500만원 이하의 과태료가 부과될 수 있습니다.
                    </div>
                  </div>
                </div>
                <EmailProfile />
              </div>
              <div className="Email_RightArea_CT" style={{ alignItems: "center" }}>
                <CertificatePDF
                  name={response ? response.name : ""}
                  birthday={response ? response.birthday : ""}
                  address={response ? response.address : ""}
                  workDate={response ? response.employmentPeriod : ""}
                  companyName={response ? response.companyName : ""}
                  telNumber={response ? response.companyNumber : ""}
                  position={response ? response.position : ""}
                  department={response ? response.department : ""}
                  jobPosition={response ? response.jobPosition : ""}
                  jobList={response ? response?.job : ""}
                  requestDate={formattedDate}
                  loseDate={response ? hiqcData.LoseDate : ""}
                />
                <div className="Email_RightArea_Body_CT" style={{ alignItems: "center" }}>
                  <div className="Email_RightArea_Body_Copy">
                    해당 경력증명서 발급 요청은 <span style={{ fontWeight: "800" }}>{memberData ? memberData.Name : ""} 님</span>
                    으로부터 <span style={{ fontWeight: "800" }}>써티드</span>와 <span style={{ fontWeight: "800" }}>호연 인사노무컨설팅</span>
                    이<br />
                    위임받았습니다. 경력증명서의 내용이 사실이라면 <span style={{ fontWeight: "800", color: "rgb(55, 105, 252)" }}>'발급 진행'</span> 버튼을
                    클릭하여
                    <br />
                    <span style={{ fontWeight: "800" }}>직인</span>과 <span style={{ fontWeight: "800" }}>대표자명</span>을 추가해주세요.
                  </div>
                </div>

                <div className="Email_RightArea_Buttons_CT">
                  <button
                    className="Email_Button"
                    style={{ marginTop: "0px" }}
                    onClick={() => {
                      amplitude.track("발급 거절 페이지 진입");
                      navigate(`/email/${token}/reject`, {
                        state: createStateObject(token, response, formattedDate, memberData, hiqcData),
                      });
                    }}
                  >
                    <div className="Email_Left_Button_BG">발급 거절</div>
                  </button>
                  <button
                    className="Email_Button"
                    style={{ marginTop: "0px" }}
                    onClick={() => {
                      amplitude.track("발급 진행 페이지 진입", {
                        유저: memberData?.name,
                        증명서종류: hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서",
                        직장명: response?.companyName,
                      });
                      navigate(`/email/${token}/issue`, {
                        state: createStateObject(token, response, formattedDate, memberData, hiqcData),
                      });
                    }}
                  >
                    <div className="Email_Right_Button_BG">발급 진행</div>
                  </button>
                </div>
              </div>
            </div>
          ) : isBlock ? (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">처리가 완료된 요청입니다.</div>
              </div>
            </div>
          ) : isExpired ? (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">처리 기한이 만료된 요청입니다.</div>
              </div>
            </div>
          ) : (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">유효하지 않은 요청입니다.</div>
              </div>
            </div>
          )}
          <div>
            <Footer />
          </div>
        </div>
      ) : (
        <div className="AllContent_Between_Detail ">
          {!isBlock && !isExpired ? (
            <div className="Email_CT">
              <div className="Email_LeftArea_CT">
                <div className="Email_LeftContents_CT">
                  <div className="Email_Main_Copy_CT">
                    <div className="Email_Main_1_Copy_CT">
                      <div className="Email_Main_2_Copy">{memberData ? memberData.Name : ""}님</div>
                      <div className="Email_Main_1_Copy">의</div>
                    </div>
                    <div className="Email_Main_1_Copy">경력증명서 발급 요청을 확인해주세요.</div>
                  </div>
                  <div className="Email_Sub_Copy_CT">
                    <div className="Email_Sub_1_Copy">
                      경력증명서의 내용을 확인하시고 발급 절차를
                      <br />
                      진행해주세요.
                    </div>
                  </div>
                  <div className="Email_InfoBox_BG_CT">
                    <div className="Email_InfoBox_BG">
                      <div className="Email_InfoBox_1_Copy_CT">
                        <div className="Email_InfoBox_1_Copy">경력증명서 발급을 간편하게 하려면?</div>
                      </div>
                      <div className="Email_InfoBox_2_Copy">
                        경력증명서 내용의 사실 여부만 확인하시면 새롭게 경력증명서를 작성할 필요 없이 바로 경력증명서를 발급할 수 있습니다.
                      </div>
                    </div>
                  </div>
                  <div className="Email_LawInfo_Copy_CT">
                    <div className="Email_LawInfo_Copy">
                      재직자가 퇴사하고 3년 이내에 경력증명서 발급을 요구하는 경우 근로기준법 제39조에 따라 7일 이내 발급 의무가 있습니다. 정당한 사유 없이
                      거부하는 경우, 근로기준법 제116조에 따라 500만원 이하의 과태료가 부과될 수 있습니다.
                    </div>
                  </div>
                </div>
              </div>
              <div className="Email_RightArea_CT" style={{ alignItems: "center" }}>
                <CertificatePDF
                  name={response ? response.name : ""}
                  birthday={response ? response.birthday : ""}
                  address={response ? response.address : ""}
                  workDate={response ? response.employmentPeriod : ""}
                  companyName={response ? response.companyName : ""}
                  telNumber={response ? response.companyNumber : ""}
                  position={response ? response.position : ""}
                  department={response ? response.department : ""}
                  jobPosition={response ? response.jobPosition : ""}
                  jobList={response ? response?.job : ""}
                  requestDate={formattedDate}
                  loseDate={response ? hiqcData.LoseDate : ""}
                />
                <div className="Email_RightArea_Body_CT">
                  <div className="Email_RightArea_Body_Copy">
                    해당 경력증명서 발급 요청은 <span style={{ fontWeight: "800" }}>{memberData ? memberData.Name : ""} 님</span>
                    으로부터 <span style={{ fontWeight: "800" }}> 써티드</span>
                    와 <br />
                    <span style={{ fontWeight: "800" }}>호연 인사노무컨설팅</span>
                    이 위임받았습니다. 경력증명서의 내용이
                    <br /> 사실이라면 <span style={{ fontWeight: "800", color: "rgb(55, 105, 252)" }}>'발급 진행'</span> 버튼을 클릭하여{" "}
                    <span style={{ fontWeight: "800" }}>직인</span>과 <span style={{ fontWeight: "800" }}>대표자명</span>을 추가해주세요.
                  </div>
                </div>

                <div className="Email_RightArea_Buttons_CT">
                  <button
                    className="Email_Button"
                    style={{ marginTop: "0px" }}
                    onClick={() => {
                      amplitude.track("발급 거절 페이지 진입");
                      navigate(`/email/${token}/reject`, {
                        state: createStateObject(token, response, formattedDate, memberData, hiqcData),
                      });
                    }}
                  >
                    <div className="Email_Left_Button_BG">발급 거절</div>
                  </button>
                  <button
                    className="Email_Button"
                    style={{ marginTop: "0px" }}
                    onClick={() => {
                      amplitude.track("발급 진행 페이지 진입", {
                        유저: memberData?.name,
                        증명서종류: hiqcData?.LoseDate === "" ? "재직증명서" : "경력증명서",
                        직장명: response?.companyName,
                      });
                      navigate(`/email/${token}/issue`, {
                        state: createStateObject(token, response, formattedDate, memberData, hiqcData),
                      });
                    }}
                  >
                    <div className="Email_Right_Button_BG">발급 진행</div>
                  </button>
                </div>
                <EmailProfile />
              </div>
            </div>
          ) : isBlock ? (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">처리가 완료된 요청입니다.</div>
              </div>
            </div>
          ) : isExpired ? (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">처리 기한이 만료된 요청입니다.</div>
              </div>
            </div>
          ) : (
            <div className="Email_Block_All_CT">
              <div className="Email_Block_CT">
                <div className="Email_Block_Icon_CT">
                  <AlreadyConfirm />
                </div>
                <div className="Email_Main_1_Copy">유효하지 않은 요청입니다.</div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
});

export default RQ02RequestDescription;
