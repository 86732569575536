import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import Profile from "../Assets/Images/Profile.png";

const EmailProfile = () => {
  return (
    <div className="Email_LawProfile_CT">
      <div className="Email_LawProfile_Left_CT">
        <img className="Email_LawProfileImage_CT" src={Profile} alt="ProfileImage" />
      </div>
      <div className="Email_LawProfile_Right_CT">
        <div className="Email_LawProfile_Intro_CT">
          <div className="Email_LawProfile_Company_Copy">호연 인사노무컨설팅</div>
          <div className="Email_LawProfile_Vertical_CT" />
        </div>
        <div className="Email_LawProfile_Detail_CT">
          <div className="Email_LawProfile_Tel_Copy">TEL : 010-6212-0225</div>
          <div className="Email_LawProfile_Tel_Copy">E-Mail : help@certifie.io</div>
        </div>
      </div>
    </div>
  );
};

export default EmailProfile;
